<template>
   <ObiButtonGroup class="obi-bs" v-bind="attrs" v-on="$listeners">
      <ObiButton
         outline
         v-bind="attrs"
         :key="item.id"
         color="switch"
         v-for="item in items"
         :active="isActive(item)"
         :icon="IconCheckWhiteBg"
         @click="selectValue(item)"
         :icon-hidden="!isActive(item)"
      >
         <slot name="item" v-if="$slots.item" v-bind:item="item" />
         <ObiText v-html="item.label" v-else />
      </ObiButton>
   </ObiButtonGroup>
</template>

<script>
import { keys, omit } from 'lodash';

import IconCheckWhiteBg from '@/components/Icons/general/IconCheckWhiteBg.svg';

export default {
   name: 'ObiButtonSwitch',
   props: {
      value: {},
      items: {
         type: Array,
         require: true,
      },
      asObject: {
         type: Boolean,
         require: false,
      },
      multi: {
         type: Boolean,
         require: false,
      },
   },

   computed: {
      classList() {
         const classList = [];

         if (this.pills) {
            classList.push('nav-pills');
         }

         if (this.vertical) {
            classList.push('flex-column');
         }

         return classList;
      },

      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },

      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
            this.$emit('navChanged', val);
         },
      },
   },

   data() {
      return {
         IconCheckWhiteBg,
      };
   },

   methods: {
      isActive(item) {
         if (this.$props.multi) {
            return this.inputValue.indexOf(item.id) !== -1;
         }

         if (this.$props.asObject) {
            return this.inputValue.id === item.id;
         }

         return this.inputValue === item.id;
      },
      selectValue(item) {
         let selectedValue = item.id;

         if (this.$props.asObject) {
            selectedValue = item;
         }

         if (this.$props.multi) {
            const index = this.inputValue.indexOf(selectedValue);
            if (this.isActive(item)) {
               this.inputValue.splice(index, 1);
               this.$emit('input', this.inputValue);
            } else {
               this.inputValue.push(item.id);
               this.$emit('input', this.inputValue);
            }
         } else {
            this.inputValue = selectedValue;
         }
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-bs {
}
</style>
